<script>

  import { get, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import CartItemList from '@/components/checkout/CartItemList'
  import SoldOutItems from '@/components/checkout/SoldOutItems'
  import ProductSlider from '@/components/marketplace/ProductSlider'
  import OrderSummary from '@/components/checkout/summary/OrderSummary'
  import CheckoutErrorModal from '@/components/checkout/CheckoutErrorModal'
  import ShippingModal from '@/components/marketplace/ShippingModal'
  import CheckoutBreakdown from '@/components/checkout/CheckoutBreakdown'
  import SubscribeMarketExitModal from '@/components/subscribe/SubscribeMarketExitModal'
  import HelpMessaging from '../components/app/HelpMessaging.vue'

  const guardMixin = createGuardMixin(
    async ({ store, redirect }) => {
      if (
        ((await store.dispatch('account/loggedInAsync')) === false ||
          !store.get('subscribe/subscriptionCreated')) &&
        !store.get('account/isAdmin')
      ) {
        return redirect({ name: 'SubscribePlan' })
      }
    },
    ['loggedIn']
  )

  export default {
    components: {
      CartItemList,
      SoldOutItems,
      ProductSlider,
      OrderSummary,
      CheckoutErrorModal,
      ShippingModal,
      CheckoutBreakdown,
      SubscribeMarketExitModal,
      HelpMessaging,
    },
    mixins: [guardMixin],
    data() {
      return {
        soldOutDetailsRef: null,
        openShippingModal: false,
        openExitModal: false,
      }
    },
    computed: {
      ...get('account', ['loggedIn', 'minTotalForFreeShipping', 'addresses']),
      ...get('upsell', [
        'calculatingLineItems',
        'placingOrder',
        'cartQuantity',
        'errors',
        'isCartEmpty',
        'mobile',
        'nonSoldOutLineItems',
        'productsInCart',
        'remainingAmountForFreeShipping',
        'remainingForFreeShippingCalculated',
        'soldOutLineItems',
        'subtotal',
        'upsellProducts',
        'selectedShippingAddressId',
      ]),
      screen,
      soldOutNotificationVisible() {
        return this.nonSoldOutLineItems.length && this.soldOutLineItems.length
      },
      soldOutVariants() {
        return this.soldOutLineItems.map(({ product }) => product.defaultVariant)
      },
      recommendedProducts() {
        return this.upsellProducts
          .filter(({ id }) => !this.productsInCart?.includes(id))
          .slice(0, 15)
      },
    },
    watch: {
      cartQuantity() {
        if (this.cartQuantity) {
          this.reviewOrder()
        }
      },
    },
    created() {
      // added here so slider products don't change if something is added from the slider
      this.clearRemovedLineItems()
      this.$store.set('upsell/selectedShippingAddressId', this.addresses[0].id)
      this.initCart()
    },
    methods: {
      ...call('upsell', [
        'clearErrors',
        'initCart',
        'removeCartItem',
        'toggleRemoveItem',
        'updateQuantity',
        'addToCart',
        'clearRemovedLineItems',
        'clearSoldOutItemsFromCart',
        'reviewOrder',
        'placeOrder',
      ]),
      scrollToSoldOutDetails() {
        this.soldOutDetailsRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      },
      async submit() {
        await this.placeOrder().then(() => {
          this.$navigate({ name: 'CheckoutConfirmation', params: { storeType: 'upsell' } })
        })
      },
      viewDetails(variant) {
        this.$navigate({
          name: 'SubscribeMarketCheckoutProduct',
          params: {
            productId: variant.productId,
            type: 'upsell',
          },
        })
      },
    },
  }
</script>

<template>
  <div class="pb-10 md:py-10 md:px-9 overflow-x-hidden">
    <!---------------------- Start mobile view ---------------------->
    <template v-if="mobile">
      <!----------- Shipping progress bar ----------->
      <div class="px-6 pt-4 pb-3 bg-dawn">
        <BaseProgressBar
          v-if="remainingForFreeShippingCalculated && minTotalForFreeShipping"
          :value="subtotal"
          :target-value="minTotalForFreeShipping"
          text-alignment="center"
        >
          <template v-slot:icon>
            <IconFreeShipping v-if="remainingAmountForFreeShipping <= 0" />
            <IconShipping v-else />
          </template>
          <span v-if="remainingAmountForFreeShipping <= 0">Congrats! You get free shipping.</span>
          <span v-else>
            {{ $formatPrice(remainingAmountForFreeShipping) }} away from FREE SHIPPING
          </span>
          <template v-slot:sublabel>
            <span v-if="remainingAmountForFreeShipping > 0">
              Free Shipping on orders over {{ $formatPrice(minTotalForFreeShipping) }}.
              <BaseLinkStyled :to="{ name: 'SubscribeMarket' }">Shop now</BaseLinkStyled>
            </span>
            <span v-else> We automatically applied it to your order. </span>
          </template>
        </BaseProgressBar>
      </div>
      <!----------- Mobile order summary and checkout button ----------->
      <CheckoutBreakdown v-if="!isCartEmpty" cart="upsell" class="mt-2" @placeOrder="submit">
        <OrderSummary type="upsell" view="checkout" />
      </CheckoutBreakdown>
      <!----------- Empty cart message ----------->
      <div v-else class="py-7 bg-dawn flex flex-col flex-wrap content-center items-center">
        <BaseHeading size="h2-lg" tag="h2"> Your cart is empty. </BaseHeading>
        <div class="pt-6 px-4 w-full">
          <BaseButton :to="{ name: 'SubscribeMarket' }"> Shop Products&nbsp; </BaseButton>
        </div>
      </div>
    </template>
    <!---------------------- End mobile view ---------------------->

    <div class="max-w-320 mx-auto">
      <div class="layout md:mt-8">
        <div>
          <!---------------------- Start desktop header ---------------------->
          <div v-if="!mobile" class="flex items-center mb-4">
            <div class="mr-3 w-11 flex-shrink-0">
              <BaseIcon>
                <IconBoxIllustration />
              </BaseIcon>
            </div>
            <div class="flex-1 flex justify-between items-baseline">
              <div class="font-body">
                <BaseHeading size="h5" tag="h1">Your Items</BaseHeading>
              </div>
              <BaseLinkStyled
                :to="{ name: 'SubscribeMarket' }"
                color="black"
                font-size="xs"
                icon-position="left"
                :icon-size="3"
                class="text-right"
              >
                <template v-slot:icon>
                  <IconArrowLeft />
                </template>
                <template v-slot>Back to shopping</template>
              </BaseLinkStyled>
            </div>
          </div>
          <!---------------------- End desktop header ---------------------->
          <!----------- Sold out notification ----------->
          <div
            v-if="soldOutNotificationVisible"
            class="mt-2 flex justify-center md:justify-start pb-4 border-b border-gray-active"
          >
            <div
              class="flex items-center pl-3 pr-12 py-2 border border-gray-active text-sm leading-tight"
            >
              <div class="mr-2 flex-shrink-0 text-green">
                <BaseIcon :size="6">
                  <IconInfo />
                </BaseIcon>
              </div>
              <div>
                {{ soldOutLineItems.length }} sold out item{{
                  soldOutLineItems.length > 1 ? 's' : ''
                }}.
              </div>
              <div class="ml-2 flex-shrink-0">
                <BaseLinkStyled @click="scrollToSoldOutDetails">View</BaseLinkStyled>
              </div>
              <div class="w-8" style="flex-shrink: 99999" />
              <div class="absolute right-0 mr-2 inset-y-0 flex items-center">
                <BaseIconClickable
                  label="Dismiss"
                  :size="3"
                  :padding="2"
                  @click="clearSoldOutItemsFromCart"
                >
                  <IconX />
                </BaseIconClickable>
              </div>
            </div>
          </div>
          <!----------- Empty cart message ----------->
          <div class="-mx-3 md:mx-0">
            <div
              v-if="isCartEmpty && !mobile"
              class="mt-4 py-7 bg-dawn flex flex-col flex-wrap content-center items-center"
            >
              <BaseHeading size="h2-lg" tag="h2"> Your cart is empty. </BaseHeading>
              <div class="pt-6 px-4 w-80">
                <BaseButton :to="{ name: 'SubscribeMarket' }"> Shop Products&nbsp; </BaseButton>
              </div>
            </div>
            <!----------- Cart items ----------->
            <CartItemList
              :line-items="nonSoldOutLineItems"
              class="px-6 md:px-0"
              @toggleRemoveItem="toggleRemoveItem"
              @removeCartItem="removeCartItem"
              @updateQuantity="updateQuantity"
              @openShippingModal="openShippingModal = true"
              @viewDetails="viewDetails($event)"
            />
          </div>
          <!----------- Sold out cart items ----------->
          <div
            v-if="soldOutLineItems.length > 0"
            v-ref="soldOutDetailsRef"
            :class="`${nonSoldOutLineItems.length > 0 ? 'border-t border-gray-active' : ''}`"
          >
            <div class="my-4 flex px-2 text-xs leading-snug">
              <div class="mr-2 flex-shrink-0 text-green">
                <BaseIcon :size="6">
                  <IconInfo />
                </BaseIcon>
              </div>
              <div>
                Sold out items have been removed from your order.<br />
                Add to waitlist and we’ll notify once available.
              </div>
            </div>
            <SoldOutItems type="marketplace" :variants="soldOutVariants" />
          </div>
        </div>
        <!---------------------- Desktop view ---------------------->
        <template v-if="!mobile">
          <div />
          <div>
            <div class="p-4 rounded-sm bg-dawn">
              <BaseProgressBar
                v-if="remainingForFreeShippingCalculated"
                :value="subtotal"
                :target-value="minTotalForFreeShipping"
              >
                <template v-slot:icon>
                  <IconFreeShipping v-if="remainingAmountForFreeShipping <= 0" />
                  <IconShipping v-else />
                </template>
                <span v-if="remainingAmountForFreeShipping <= 0">You get Free Shipping!</span>
                <span v-else>
                  {{ $formatPrice(remainingAmountForFreeShipping) }} away from FREE SHIPPING
                </span>
                <template v-slot:sublabel>
                  <span v-if="remainingAmountForFreeShipping > 0">
                    Free Shipping on orders over {{ $formatPrice(minTotalForFreeShipping) }}.
                    <BaseLinkStyled :to="{ name: 'SubscribeMarket' }">Shop now</BaseLinkStyled>
                  </span>
                  <span v-else> We automatically applied it to your order. </span>
                </template>
              </BaseProgressBar>
            </div>
            <CheckoutBreakdown v-if="!isCartEmpty" cart="upsell" class="mt-2" @placeOrder="submit">
              <OrderSummary type="upsell" view="checkout" />
            </CheckoutBreakdown>
            <div class="mt-2 text-center leading-tight text-xs">
              <HelpMessaging />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="isCartEmpty" class="my-8" />
    <BaseDivider v-else class="my-8" />
    <!----------- Recommended products slider ----------->
    <div class="overflow-hidden mx-auto max-w-330 px-6 md:px-4 2xl:px-0">
      <ProductSlider
        :products="recommendedProducts"
        has-add-to-cart-button
        type="upsell"
        class="max-w-320 mx-auto"
        @addToCart="addToCart"
      >
        <template v-slot:heading>Add more - limited time access:</template>
      </ProductSlider>
    </div>

    <Portal to="productModal">
      <RouterView />
    </Portal>

    <BaseContainer max-width="sm" class="mt-15 sm:mt-25">
      <BaseButton outline-only @click="openExitModal = true">
        No thanks, visit my account
      </BaseButton>
    </BaseContainer>

    <Portal to="modal">
      <SubscribeMarketExitModal :open="openExitModal" @dismiss="openExitModal = false" />
      <CheckoutErrorModal
        v-if="errors"
        store-type="upsell"
        :errors="errors"
        @clearErrors="clearErrors"
      />
    </Portal>
    <ShippingModal :open="openShippingModal" type="upsell" @dismiss="openShippingModal = false" />

    <!----------- Loading spinner ----------->
    <BaseSpinner
      v-if="calculatingLineItems || placingOrder"
      overlay="fixed"
      overlay-color="dawn-lt3"
      class="z-10"
    />
  </div>
</template>

<style lang="postcss" scoped>
  .layout {
    @apply grid;

    @screen md {
      grid-template-columns: 628fr 76fr theme('spacing.82');
    }
  }
</style>
