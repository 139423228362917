<script>
  import screen from '@/helpers/screen'

  export default {
    props: {
      open: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      screen,
    },
  }
</script>

<template>
  <BaseModal size="sm-md" :open="open" bg-color="dawn" dismissible @dismiss="$emit('dismiss')">
    <div class="flex flex-col items-center max-w-130 mx-auto">
      <div class="font-heading text-center mb-4">
        <span class="font-medium text-2xl"> ONE-TIME-ONLY SHOP </span>
        <br />
        <div class="max-w-80 xs:max-w-none font-semibold text-40px md:text-7xl leading-none">
          Are You Sure You Want to Leave the Market?
        </div>
      </div>
      <div class="text-center text-sm max-w-115 mx-auto">
        This is your only chance to shop the New Members Market. <br v-if="screen.md" />
        If you leave now, you won’t be able to scoop up these discounted goods until the next
        seasonal Add-On Market. Are you positive you want to say goodbye to these good buys?
        <div class="mt-8 max-w-78 mx-auto">
          <BaseButton @click="$emit('dismiss')"> I want to continue shopping </BaseButton>
          <BaseButton outline-only class="mt-2" :to="{ name: 'Home' }">
            Take me to my account
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseModal>
</template>
